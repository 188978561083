import moment from 'moment'

/**
 * Business Hours, 24hr format (military time)
 */

// const businessHours = [
//   { id: 1, dow: 0, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 2, dow: 1, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 3, dow: 2, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 4, dow: 3, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 5, dow: 4, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 6, dow: 5, start: '06:00', end: '20:00', tag: 'cafe' },
//   { id: 7, dow: 6, start: '06:00', end: '20:00', tag: 'cafe' },
// ]

const format = 'hh:mm'

const now = moment(),
      today = moment().day(),
      startTime = moment('06:00', format),
      endTime = moment('20:00', format)

const isCurrentlyOpen = () => (
  now.isBetween(startTime, endTime) && true
)

const currentMsg = () => (
  isCurrentlyOpen()
    ? 'closes at 8pm'
    : 'we\'ll we back at 6am'
)

const thisWeek = () => 'Mon-Thu: 6-8pm, Fri-Sun: 7-6pm'

// const todayHours = () => bus

const getBusinessHours = () => {
    // console.log('----- ', moment().day())
    return {
      isCurrentlyOpen,
      currentMsg,
      thisWeek,
      today,
    }
}

export default getBusinessHours()
