import filter from 'lodash/filter'
import includes from 'lodash/includes'
import reject from 'lodash/reject'
// import chunk from 'lodash/chunk'
import React from 'react'
import { withRouter } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'

import imgSplash from '../../media/images/cafesplash.png'
import db from '../../db'

const coffeeMenu = filter(db.menu, item  => (
  includes(item.category, 13)
  && reject(item.category, 4)
  && item
))

const unCoffeeMenu = filter(db.menu, item  => (
  includes(item.category, 14)
  && reject(item.category, 4)
  && item
))

const extraMenu = filter(db.menu, item  => (
  includes(item.category, 15)
  && item
))

const wineList = filter(db.menu, item => (
  (
    includes(item.category, 16)
    || includes(item.category, 5)
    || includes(item.category, 6)
    || includes(item.category, 7)
    || includes(item.category, 8)
  )
  && item
))

const beerList = filter(db.menu, item => (
  includes(item.category, 9)
  && item
))

const cocktailList = filter(db.menu, item => (
  includes(item.category, 10)
  && item
))

const listGroup = (listGroup, header, s=null, m=null, l=null) => (
  <div className="divTable blueTable" style={{ fontSize: '0.9em'}}>
    <div
      className="divTableHeading sticky"
      style={{ background: '#212020', top: 132 }}
    >
      <div className="dtr">
        <div className="divTableHead" style={{ fontSize: '1.1em', color: '#ffffff', padding: '7px 0 7px 10px' }}>{header}</div>
        <div className="divTableHead" style={{ fontWeight: 300, color: '#bebebe', padding: '7px 0' }}>{s && s}</div>
        <div className="divTableHead" style={{ fontWeight: 300, color: '#bebebe', padding: '7px 0' }}>{m && m}</div>
        <div className="divTableHead" style={{ fontWeight: 300, color: '#bebebe', padding: '7px 0' }}>{l && l}</div>
      </div>
    </div>
    <div className="divTableBody">
      {listGroup.map(item => (
        <div key={item.id} className="dtr">
          <div className="dtc" style={{ position: 'relative', left: 10 }}>
            <span style={{ color: '#d2c8c3' }}>{item.title}</span>
            {
              item.description && (item.description !== '')
              && <span style={{ fontSize: '0.9em', display: 'block', color: '#69615e' }}>{item.description}</span>
            }
          </div>
          <div className="dtc">{item.price[0] ? item.price[0].amount : item.price}</div>
          {(typeof item.price !== 'number')
            && (
              <>
                <div className="dtc">{item.price[1] && item.price[1].amount}</div>
                <div className="dtc">{item.price[2] && item.price[2].amount}</div>
              </>
            )
          }
        </div>
      ))}
    </div>
  </div>
)

class MenuCafe extends React.Component {


  state = {
    height: 0
  }

  toggle = () => {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state
    return (
      <div
        style={{
          position: 'relative',
          display: 'block',
          background: '#272727',
          boxShadow: '0 2px 26px 0 rgba(0,0,0,0.22)',
          clear: 'both',
          height: 'auto',
          width: '100%',
          margin: 'auto',
          color: '#CECECE',
        }}
      >

        <div className="row" style={{ margin: '0 auto 20px auto', maxWidth: 480 }}>
          <h4
            className="sticky"
            style={{
              textAlign: 'center',
              color: '#CECECE',
              width: '100%',
              background: '#272727',
              fontSize: 28,
              margin: 0,
              paddingTop: 0,
              fontWeight: 400,
              top: 83,
              textTransform: 'uppercase',
              zIndex: 100,
            }}
          >
            Cafe Menu
          </h4>

          <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 15 }}>
            <img src={imgSplash} alt="" style={{ width: '95%', maxWidth: 480, margin: 'auto', }} />
          </div>


          <AnimateHeight duration={500} height={height}>
            {[
              listGroup(coffeeMenu, 'Coffee', 'cup', 'mug', 'pint'),
              listGroup(unCoffeeMenu, 'Un-Coffee', 'sm', 'md', 'lg'),
              listGroup(extraMenu, 'Extras', '', '', ''),
              listGroup(wineList, 'Wines', '', '', ''),
              listGroup(beerList, 'Beers', '', '', ''),
              listGroup(cocktailList, 'Cocktails', '', '', ''),
            ]}
          </AnimateHeight>

          <button
            onClick={this.toggle}
            type="button"
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: '100%',
              padding: 0,
              textAlign: 'center',
              background: 'none',
              border: 'none',
              fontFamily: '"Open sans", Helvetica, sans-serif',
              color: '#96BC5A',
              textTransform: 'lowercase',
              fontSize: 16,
              outline: 'none',
            }}
          >
            {height === 0 ? 'View Full Menu' : 'Less Menu'}
          </button>
        </div>
      </div>
    )
  }
}

export default withRouter(MenuCafe)
