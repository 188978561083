import React from 'react'
import { Link } from 'react-router-dom'

import imgLogo from '../../media/images/logo.svg'
import imgLogoLight from '../../media/images/logoLight.svg'

const Header = ({ isOpen }) => (
  <header
    className="sticky"
    style={{
      display: 'block',
      maxWidth: 1024,
      top: 0,
      textAlign: 'center',
      width: '100%',
      padding: '11px 0 7px 0',
      borderTop: `10px solid ${isOpen ? '#9FC65D' : '#5c6d3e'}`,
      borderBottom: '7px solid #3F5C76',
      background: isOpen ? '#fff' : '#1A1D23',
      zIndex: 101,
    }}
  >

    <Link to="/">
      <img
        src={isOpen ? imgLogo : imgLogoLight}
        alt="Welcome to Cafe 2894 On Main"
        style={{ width: 170 }}
      />
    </Link>

  </header>
);

export default Header;
