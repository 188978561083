import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => (
  <ul
    className="social-icons"
    style={{
      listStyleType: 'none',
      margin: '8px 13px 15px 10px',
      padding: 0,
      fontSize: 33,
    }}
  >
    <li>
      <a
        href={process.env.REACT_APP_FACEBOOK_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'facebook']} />
      </a>
    </li>
    <li>
      <a
        href={process.env.REACT_APP_FOURSQUARE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'foursquare']} />
      </a>
    </li>
    <li>
      <a
        href={process.env.REACT_APP_INSTAGRAM_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'instagram']} />
      </a>
    </li>
  </ul>
)
