import filter from 'lodash/filter'
import includes from 'lodash/includes'
import chunk from 'lodash/chunk'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Carousel from 'nuka-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import db from '../../db'

const menu = filter(db.menu, item  => (
  (
    includes(item.category, 1)
    || includes(item.category, 2)
    || includes(item.category, 3)
  )
  && item
))

const KitchenAtAGlance = (props) => (
  <div
    style={{
      position: 'relative',
      background: '#fff',
      width: '100%',
      padding: '20px 0',
    }}
  >

    <div className="row" style={{ marginBottom: 15 }}>
      <h4
        style={{
          fontSize: 20,
          margin: '0 0 0 20px',
          textTransform: 'uppercase',
        }}
      >
        Kitchen Menu At-a-glance
      </h4>

      <button
        onClick={() => props.history.push('/menu/breakfast')}
        type="button"
        style={{
          background: 'none',
          border: 'none',
          fontFamily: '"Open sans", Helvetica, sans-serif',
          color: '#3D5A74',
          textTransform: 'lowercase',
          position: 'absolute',
          fontSize: 16,
          right: 5,
          top: 20,
        }}
      >
        View Full Menu
      </button>
    </div>

    <Carousel
      initialSlideHeight={1000}
      heightMode="max"
      // renderCenterLeftControls={() => null}
      // renderCenterRightControls={() => null}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          type="button"
          onClick={previousSlide}
          style={{
            border: 'none',
            background: 'none',
            position: 'relative',
            left: -5,
            color: '#ccc'
          }}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          type="button"
          onClick={nextSlide}
          style={{
            border: 'none',
            background: 'none', position: 'relative', right: -5, color: '#ccc'
          }}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      )}
      renderBottomCenterControls={
        ({ currentSlide, slideCount }) => (
          Array(slideCount).fill().map((x, index) => (
            <span
              key={index}
              style={{
                color: currentSlide === index
                  ? '#A6CF72'
                  : '#c1c1c1',
                fontSize: 24,
                marginRight: 10,
              }}
            >
              •
            </span>
          ))
        )
      }
    >
      {chunk(menu, 3).map((page, index) => (
        <div key={index}>
          <ul
            style={{
              listStyleType: 'none',
              margin: 0,
              padding: '15px 20px 0 20px',
            }}
          >
            {page.map(item => (
              <li key={item.id} style={{ marginBottom: 25, }}>

                <span style={{ float: 'right', }}>
                  {
                    /* item.price */
                    typeof item.price === 'number'
                      ? item.price
                      : item.price.map(p => p).join(' / ')
                  }
                </span>

                <strong
                  style={{
                    color: '#465476',
                    textTransform: 'uppercase',
                    display: 'block',
                  }}
                >
                  {item.title}
                </strong>

                <span
                  style={{
                    fontSize: '0.9em',
                    color: '#6a6a6a',
                    display: 'block',
                    marginTop: 5,
                    lineHeight: '1.2em',
                  }}
                >
                  {item.description}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Carousel>

  </div>
)

export default withRouter(KitchenAtAGlance)
