import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Header, Footer } from './blocks'
import {
  Frontpage, PrivacyPolicy, TermsOfService, Error404
} from './pages'

import getBusinessHours from '../utils'

export default (props) => (
  <div
    style={{
      position: 'relative',
      maxWidth: '1024px',
      margin: 'auto',
      background: '#ECE7E2',
    }}
  >

    <Header isOpen={getBusinessHours.isCurrentlyOpen()} {...props} />

    <Switch>
      <Route exact path="/" component={() => <Frontpage {...props} />} />
      <Route exact path="/privacy-policy" component={() => <PrivacyPolicy {...props} />} />
      <Route exact path="/terms-of-service" component={() => <TermsOfService {...props} />} />
      <Route component={() => <Error404 {...props} />} />
    </Switch>

    {/* <Block.AuthComponent {...props} /> */}

    <Footer />

  </div>
)
