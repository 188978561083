import React from 'react'
import { withRouter } from 'react-router-dom'

import getBusinessHours from '../../utils'
import { SocialIcons } from '../micro'

const ContactCard = props => (
  <div
    className="row"
    style={{
      margin: 'auto',
      background: '#ECE7E2',
      padding: '0 10px',
      maxWidth: 570,
    }}
  >

    <div className="col" style={{ width: '47%', textAlign: 'right', }}>

      <div
        style={{
          marginBottom: 20,
          padding: '9px 0',
          borderRadius: 3,
          width: '100%',
          textAlign: 'center',
          backgroundColor: getBusinessHours.isCurrentlyOpen() ? '#A0C563' : '#1A1D23',
          border: '1px solid rgba(7,7,7,0.06)',
          boxShadow: '0 2px 10px 0 rgba(0,0,0,0.09)',
          textShadow: '0 2px 0 0 black',
          fontFamily: '"Open sans", Helvetica, sans-serif',
          fontSize: 16,
          color: '#fff',
        }}
      >

        <span
          style={{
            color: '#fff',
            fontSize: '1em',
            fontWeight: getBusinessHours.isCurrentlyOpen() ? 'bold' : 'normal',
            textTransform: 'uppercase'
          }}
        >
          {getBusinessHours.isCurrentlyOpen() ? 'open' : 'closed'}
        </span>

        <span
          style={{
            position: 'relative',
            top: -1,
            display: 'inline-block',
            fontSize: 12,
            marginLeft: 10,
            color: getBusinessHours.isCurrentlyOpen() ? '#e7f2d6' : '#a4d167'
          }}
        >
          {getBusinessHours.currentMsg()}
        </span>

      </div>

      <ul>
        <li className="header">Kitchen Hours</li>
        <li><span>Sun & Mon:</span> 8am - 3pm</li>
        <li><span>Tues - Sat:</span> 8am - 8pm</li>
      </ul>

      <ul>
        <li className="header">Cafe Hours</li>
        <li><span>Mon:</span> 6am - 6pm</li>
        <li><span>Tue - Fri:</span> 6am - 8pm</li>
        <li><span>Sat:</span> 7am - 8pm</li>
        <li><span>Sun:</span> 8am - 4pm</li>
      </ul>

    </div>

    <div
      className="col"
      style={{
        width: '46%',
        marginLeft: 20,
        textAlign: 'left',
      }}
    >

      <SocialIcons />

      <ul style={{ marginBottom: 10, position: 'relative', top: 3, }}>
        <li className="header">Location</li>
        <li>2894 Main St.</li>
        <li>East Troy, WI 53120</li>
      </ul>

      <a
        href="https://goo.gl/maps/6Tgpv8UAdbw"
        rel="noopener noreferrer"
        style={{
          display: 'block',
          padding: '10px 0',
          border: 0,
          fontSize: 14,
          fontFamily: '"Open sans", Helvetica, sans-serif',
          background: '#3C5C76',
          textAlign: 'center',
          width: '100%',
          borderRadius: 5,
          marginBottom: 13,
          marginTop: 10,
          color: '#fff',
        }}
      >
        Get Directions
      </a>

      <ul>
        <li className="header">Contacts</li>
        <li><a href="tel:262-642-9600">(262) 642-9600</a></li>
        <li><a href="mailto:cafe@2894onmain.com">cafe@2894onmain.com</a></li>
      </ul>

    </div>

  </div>
)

export default withRouter(ContactCard)
