import filter from 'lodash/filter'
import includes from 'lodash/includes'
import reject from 'lodash/reject'
import chunk from 'lodash/chunk'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Carousel from 'nuka-carousel'

import db from '../../db'

const coffeeMenu = filter(db.menu, item  => (
  includes(item.category, 13)
  && reject(item.category, 4)
  && item
))

const unCoffeeMenu = filter(db.menu, item  => (
  includes(item.category, 14)
  && reject(item.category, 4)
  && item
))

const extraMenu = filter(db.menu, item  => (
  includes(item.category, 15)
  && item
))

const carouselListGroup = (listGroup, header, s=null, m=null, l=null) => (
  chunk(listGroup, 6).map((page, index) => (
    <div key={index} className="divTable blueTable">
      <div className="divTableHeading">

        <div className="dtr">
          <div className="divTableHead" style={{ color: '#474747' }}>{header}</div>
          <div className="divTableHead" style={{ color: '#bebebe' }}>{s && s}</div>
          <div className="divTableHead" style={{ color: '#bebebe' }}>{m && m}</div>
          <div className="divTableHead" style={{ color: '#bebebe' }}>{l && l}</div>
        </div>

      </div>
      <div className="divTableBody">

        {page.map(item => (
          <div key={item.id} className="dtr">
            <div className="dtc">{item.title}</div>
            <div className="dtc">{item.price[0] && item.price[0].amount}</div>
            <div className="dtc">{item.price[1] && item.price[1].amount}</div>
            <div className="dtc">{item.price[2] && item.price[2].amount}</div>
          </div>
        ))}

      </div>
    </div>
  ))
)

const CafeAtAGlance = (props) => (
  // console.log('coffeeMenu -----', coffeeMenu),
  // console.log('unCoffeeMenu -----', unCoffeeMenu),
  <div
    style={{
      position: 'relative',
      display: 'block',
      background: '#272727',
      boxShadow: '0 2px 26px 0 rgba(0,0,0,0.22)',
      clear: 'both',
      height: 320,
      width: '100%',
      margin: 'auto',
      padding: 20,
      color: '#CECECE',
    }}
  >

    <div className="row" style={{ marginBottom: 20 }}>
      <h4
        style={{
          color: '#CECECE',
          fontSize: 20,
          margin: 0,
          fontWeight: 400,
          textTransform: 'uppercase',
        }}
      >
        Cafe Menu At-a-glance
      </h4>

      <button
        onClick={() => props.history.push('/menu/drinks')}
        type="button"
        style={{
          background: 'none',
          border: 'none',
          fontFamily: '"Open sans", Helvetica, sans-serif',
          color: '#96BC5A',
          textTransform: 'lowercase',
          position: 'absolute',
          fontSize: 16,
          right: 5,
          top: 22,
        }}
      >
        View Full Menu
      </button>
    </div>

    <Carousel
      initialSlideHeight={200}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          type="button"
          onClick={previousSlide}
          style={{
            border: 'none',
            background: 'none',
            position: 'relative',
            left: -20,
            color: '#525252',
          }}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          type="button"
          onClick={nextSlide}
          style={{
            border: 'none',
            background: 'none',
            position: 'relative',
            right: -20,
            color: '#525252',
          }}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      )}
      renderBottomCenterControls={
        ({ currentSlide, slideCount }) => (
          Array(slideCount).fill().map((x, index) => (
            <span
              key={index}
              style={{
                color: currentSlide === index
                  ? '#A6CF72'
                  : '#494949',
                fontSize: 30,
                marginRight: 10,
              }}
            >
              •
            </span>
          ))
        )
      }
    >
      {[
        carouselListGroup(coffeeMenu, 'Coffee', 'cup', 'mug', 'pint'),
        carouselListGroup(unCoffeeMenu, 'Un-Coffee', 'sm', 'md', 'lg'),
        carouselListGroup(extraMenu, 'Extras', '', '', '')
      ]}
    </Carousel>
  </div>
)

export default withRouter(CafeAtAGlance)
