/**
 * Style initialization is in it's own file because
 * both create-react-app and stroybook use need it.
 */

// import 'tachyons'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './css/style.css'

// Build a Library to Reference Icons Throughout App
// Fonts included here are then available globally
// See: https://goo.gl/doUeQS
library.add(
  fab,
  // Note: You can import individual icons (subsetting).
  // Only these icons will be included in build.
  faStar,
  faChevronLeft,
  faChevronRight,
)
