import '@babel/polyfill'

import React from 'react'
import { hydrate, render } from 'react-dom'
// import ReactGA from 'react-ga'
import { BrowserRouter as Router } from 'react-router-dom'
// import { BaseComponent } from 'architecture'

// import './engines'
import './media'

import App from './components/App'

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UA)
// const logPageView = () => {
//   ReactGA.set({ page: window.location.pathname })
//   ReactGA.pageview(window.location.pathname)
// }

// On every internal route change, scroll the user back to the
// top of the page and log their page view to Google Analyics.
// const handleUpdate = () => {
//   if (this.state.location.action === 'PUSH') {
//     window.scrollTo(0, 0)
//   }
//   logPageView()
// }

const AppWrapper = () => (
  <Router>
    {/* <BaseComponent Component={App} /> */}
    <App />
  </Router>
)

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<AppWrapper />, rootElement)
} else {
  render(<AppWrapper />, rootElement)
}
