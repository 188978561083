import find from 'lodash/find'
import includes from 'lodash/includes'
import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import * as db from '../../db'

const menuBarItems = db.category.filter(obj => (
  obj.id === 1  // Breakfast
  || obj.id === 2  // Lunch
  || obj.id === 3  // Evening
  || obj.id === 12   // Drinks
))

const MenuBar = () => (
  <ul
    style={{
      margin: 0,
      display: 'block',
      position: 'fixed',
      top: 102,
      width: '100%',
      maxWidth: 1024,
      padding: '5px 20px 10px 20px',
      background: '#3E5C76',
      listStyleType: 'none',
    }}
  >
    {menuBarItems.map(item => (
      <li key={item.id} style={{ display: 'inline-block', marginRight: 10 }}>
        <NavLink
          to={`/menu/${item.slug}`}
          activeStyle={{ color: '#fff' }}
          style={{
            color: '#96A6B4',
            textDecoration: 'none'
          }}
        >
          {item.title}
        </NavLink>
      </li>
    ))}
  </ul>
)

const FoodDrinkMenu = ({ match }) => {
  const activeCat = find(db.category, { slug: match.params.slug })
  const menu = db.menu.filter(obj => (
    includes(obj.category, activeCat.id)
  ))
  // console.log(' activeCat: ', activeCat)
  return (
    <div
      style={{
        marginBottom: 25,
        paddingBottom: 25,
        background: '#fff',
        boxShadow: '0 3px 13px 0 rgba(0,0,0,0.08)',
      }}
    >

      <MenuBar />

      <div style={{ marginTop: 34, }}>
        <ul
          style={{
            margin: 0,
            padding: '15px 15px 0 15px',
            listStyleType: 'none',
          }}
        >

          {menu.map(item => (
            <li key={item.id} style={{ marginBottom: 25, }}>

              <span style={{ float: 'right', }}>
                {/* item.price */}
              </span>

              <strong
                style={{
                  color: '#465476',
                  textTransform: 'uppercase',
                  display: 'block',
                }}
              >
                {item.title}
              </strong>

              <span
                style={{
                  fontSize: '0.9em',
                  color: '#6a6a6a',
                  display: 'block',
                  marginTop: 5,
                  lineHeight: '1.2em',
                }}
              >
                {item.description}
              </span>

            </li>
          ))}

        </ul>
      </div>

    </div>
  )
}

export default withRouter(FoodDrinkMenu)
