import filter from 'lodash/filter'
import includes from 'lodash/includes'
import React from 'react'
import { withRouter } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'

import db from '../../db'

const menu = filter(db.menu, item  => (
  (
    includes(item.category, 1)
    || includes(item.category, 2)
    || includes(item.category, 3)
  )
  && item
))

const menuBreakfast = filter(menu, item => includes(item.category, 1))
const menuLunch = filter(menu, item => includes(item.category, 2))
const menuEvening = filter(menu, item => includes(item.category, 3))

const listGroup = (listGroup, header) => (
  <div style={{ maxWidth: 480, margin: 'auto' }}>

    {header && header !== '' && (
      <div
        className="sticky"
        style={{
          display: 'block',
          marginBottom: 15,
          paddingTop: 10,
          paddingBottom: 5,
          color: '#3D5C75',
          width: '100%',
          fontSize: 17,
          fontWeight: 600,
          borderBottom: '1px solid #a8b5bf',
          top: 130,
          textAlign: 'center',
          background: '#fff',
          zIndex: 99,
        }}
      >
        {header}
      </div>
    )}

    {listGroup.map(item => (
      <div key={item.id} style={{ marginBottom: 25 }}>

        <span
          style={{
            float: 'right',
            lineHeight: '0.5em',
            color: '#818181',
            fontSize: '0.9em',
            fontWeight: 'bold'
          }}
        >
          {
            typeof item.price === 'number'
              ? item.price
              : item.price.map(p => p).join(' / ')
          }
        </span>

        <strong
          style={{
            paddingLeft: 10,
            color: '#465476',
            textTransform: 'uppercase',
            display: 'block',
            lineHeight: '1em',
            fontSize: '0.9em',
          }}
        >
          {item.title}
        </strong>

        <span
          style={{
            fontSize: '0.87em',
            color: '#737272',
            display: 'block',
            marginTop: 5,
            marginLeft: 10,
            position: 'relative',
            lineHeight: '1.1em',
            width: '95%',
            maxWidth: 420,
          }}
        >
          {item.description}
        </span>
      </div>
    ))}
  </div>
)

class MenuKitchen extends React.Component {

  state = {
    height: 0
  }

  toggle = () => {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state
    return (
      <div
        style={{
          position: 'relative',
          background: '#fff',
          width: '95%',
          margin: 'auto',
          padding: 0,
          border: '3px solid #597991',
          borderRadius: 3,
        }}
      >

        <div className="row" style={{ margin: '0 10px', }}>
          <h4
            className="sticky"
            style={{
              fontSize: 28,
              textAlign: 'center',
              margin: 0,
              padding: 0,
              textTransform: 'uppercase',
              top: 83,
              width: '100%',
              background: '#fff',
              zIndex: 100,
            }}
          >
            Kitchen Menu
          </h4>

          <p style={{ marginBottom: 0, textAlign: 'center', fontWeight: 700 }}>
            Soups • Salads • Sandwiches • Bowls
          </p>

          <p style={{ marginTop: 0, textAlign: 'center', fontSize: '0.8em' }}>
            Made from local, organic ingredients that change with the seasons.
          </p>

          <AnimateHeight duration={500} height={height} animateOpacity>
            {[
              listGroup(menuBreakfast, 'Breakfast'),
              listGroup(menuLunch, 'Lunch'),
              listGroup(menuEvening, 'Evening'),
            ]}
          </AnimateHeight>

          <button
            onClick={this.toggle}
            type="button"
            style={{
              marginTop: 10,
              marginBottom: 20,
              width: '100%',
              padding: 0,
              outline: 'none',
              background: 'none',
              border: 'none',
              fontFamily: '"Open sans", Helvetica, sans-serif',
              color: '#3D5A74',
              textTransform: 'lowercase',
              fontSize: 16,
            }}
          >
            {height === 0 ? 'View Full Menu' : 'Less Menu'}
          </button>
        </div>

      </div>
    )
  }
}

export default withRouter(MenuKitchen)
