module.exports = {
  category: [
    { id: 1, title: 'Breakfast', slug: 'breakfast', description: 'Changes Regularly - Febuary 1, 2016', },
    { id: 2, title: 'Lunch', slug: 'lunch', description: 'Changes Regularly - Febuary 1, 2016', },
    { id: 3, title: 'Evening', slug: 'evening', description: 'from 4pm - 8pm Tues. - Sat.', },
    { id: 4, title: 'Wine, Beer, Cocktails', },
    { id: 5, title: 'Sparkling', },
    { id: 6, title: 'Rose', },
    { id: 7, title: 'White', },
    { id: 8, title: 'Red', },
    { id: 9, title: 'Beer', },
    { id: 10, title: 'Cocktails', },
    { id: 11, title: 'Cafe', },
    { id: 12, title: 'Drinks', slug: 'drinks', },
    { id: 13, title: 'Coffee', slug: 'coffee', },
    { id: 14, title: 'Un-coffee', slug: 'uncoffee', },
    { id: 15, title: 'Extras', slug: 'extras', },
    { id: 16, title: 'Wine of the Week', slug: 'wine-of-the-week', },
  ],
  menu: [
    {
      id: 1,
      price: 10,
      title: 'Classic Breakfast',
      description: 'local organic & pastured scrambled eggs / applewood smoked bacon / roasted potatoes / house sourdough toast',
      category: [1, 3],
      rank: 0,
    },
    {
      id: 2,
      price: 9.5,
      title: 'Bacon Egg & Cheese Croissant',
      description: 'organic scrambled eggs / smoked bacon / organic cheddar & colby / house croissant (make it vegetarian with roasted tomatoes and avocado)',
      category: [1],
      rank: 1,
    },
    {
      id: 3,
      price: 9,
      title: 'Organic Ancient Grain Porridge (v)',
      description: 'quinoa / amaranth / millet / steel cut oats / candied walnutes / organic banana / organic dried apricot / hemp seed',
      category: [1],
      rank: 2,
    },
    {
      id: 4,
      price: 12,
      title: 'Apple Cobbler Pancakes (veg)',
      description: 'organic buttermilk OR organic buckwheat (no gluten) / organic blueberries / maple caramel / granola streusel / organic whipped cream (streusel contains almonds, walnuts & coconut)',
      category: [1, 3],
      rank: 3,
    },
    {
      id: 5,
      price: 11.5,
      title: 'Beef Chorizo Breakfast Burrito',
      description: 'Rohrganics spicy ground beef / organic black beans / organic carrots / organic caramelized onions / scrambled eggs / ceddar / colby jack / cilantro lime crema / red pepper salsa (make it vegetarian with wilted greens and avocado)',
      category: [1],
      rank: 4,
    },
    {
      id: 6,
      price: 8.5,
      title: 'Granola Bowl (veg)',
      description: 'house-made ﬂax & coconut granola / organic yogurt / organic fruit / local raw honey (contains walnuts & almonds)',
      category: [1],
      rank: 5,
    },
    {
      id: 7,
      price: 12,
      title: 'Gado Gado Beef, Broccoli & Brown Rice Bowl',
      description: 'organic broccoli / organic brown rice / kimchi  spicy peanut sauce / pea shoots / sunny eggs (make it vegetarian with organic mushrooms or make it vegan with baked tofo instead of eggs)',
      category: [1, 3],
      rank: 6,
    },
    {
      id: 8,
      price: 13,
      title: 'Farmer’s Market Vegetable Hash (veg)',
      description: 'local & organic vegetables / kale pesto / poached eggs / goat cheese / preserved organic lemon / organic radish / sprouts',
      category: [1],
      rank: 7,
    },
    {
      id: 9,
      price: 11.5,
      title: 'Grilled Swiss, Bacon & Mushroom',
      description: 'local organic sunflower sprouts / Carr Valley WI swiss / caramelized organic onions / organic roasted garlic aioli / house sourgdough (make it vegetarian with sauteed greens)',
      category: [2],
      rank: 8,
    },
    {
      id: 10,
      price: 7,
      title: 'Grilled Cheese',
      description: 'house made artisan bread / organic butter / organic cheddar & colby',
      category: [2, 3],
      rank: 9,
    },
    {
      id: 11,
      price: 11,
      title: 'Roasted Carrot Tacos (vegan)',
      description: 'organic black beans / organic brown rice / organic avocado / organic cabbag slaw / cilantro-lime crema / house tortilla (made from corn & wheat flour)',
      category: [2, 3],
      rank: 10,
    },
    {
      id: 12,
      price: 12,
      title: 'Roasted Squash, Farro & Apple Salad (v)',
      description: 'delicata squash / kale / brussels / celery / dates / candied pepitas / walnut "parmesan" crumble / maple-tahini sauce',
      category: [2, 3],
      rank: 11,
    },
    {
      id: 13,
      price: 12,
      title: 'Grassfed Rohrganics Meatball Sandwich',
      description: 'local organic sunflower sprouts / Carr Valley WI swiss / caramelized organic onions / organic roasted garlic aioli / house sourgdough (make it vegetarian with sauteed greens)',
      category: [2, 3],
      rank: 12,
    },
    {
      id: 14,
      price: 13,
      title: 'Chicken Salad Cob',
      description: 'Three Brother\'s Farm pastured chicken salad / organic greens / dragon\'s tongue beans / Hook\'s Paradise Blue / avacado / roasted tomatoes / Rohrganic slab bacon / jammy egg / French vinaigrette',
      category: [2, 3],
      rank: 13,
    },
    {
      id: 15,
      price: 12,
      title: 'Falafel Burger (v)',
      description: 'lettuce / cucumber / tomato / pickled ramps / house bun / kimchi-cashew-herb sauce / petite green salad',
      category: [2],
      rank: 14,
    },
    {
      id: 16,
      price: 9,
      title: 'Quiche',
      description: 'daily selection / petite green salad',
      category: [2],
      rank: 15,
    },
    {
      id: 17,
      price: [4, 7],
      title: 'Soup of the day',
      description: 'daily selection / house sourdough or pita chips (served with vegan soups)',
      category: [2],
      rank: 16,
    },
    {
      id: 18,
      price: 13.5,
      title: 'Cheese & Charcuterie',
      description: 'varying selections / fruit / nuts / honey / pickles / fruit / crostini',
      category: [3],
      rank: 17,
    },
    {
      id: 19,
      price: 14,
      title: 'Summer Vegetable Risotto',
      description: 'zucchini / mushrooms  / eggplant / roasted tomato / kale pesto / parmesan / heirloom broccoli / add Three Bros. Farm pastured chicken +5',
      category: [3],
      rank: 18,
    },
    {
      id: 20,
      price: 13,
      title: 'Rohrganics Pork Loin Chop',
      description: 'potato / sweet onion / bacon / spinach / mustard / chow chow',
      category: [3],
      rank: 19,
    },
    {
      id: 21,
      price: 15,
      title: 'Rohrganics Pork Shoulder Tacos',
      description: 'brown rice / organic beans / avocado / mango salsa',
      category: [3],
      rank: 20,
    },
    {
      id: 22,
      price: 15,
      title: 'Roasted Carrot Tacos (vegan)',
      description: 'organic black beans / organic brown rice / organic avacado / organic cabbag slaw / cilantro-lime crema / house tortilla (made from corn & wheat flour)',
      category: [3],
      rank: 21,
    },


    // WINE ******************************************************************
    {
      id: 23,
      price: [
        { id: 1, amount: 7 },
        { id: 2, amount: 20 },
      ],
      title: 'Vouvray',
      description: "bourillon dorleans / la coulee d'argent / vieille vignes / organic / '15",
      body: 'apple, ginger, dried quince, mineral',
      category: [4, 16],
      rank: 0,
    },
    {
      id: 23,
      price: 8,
      title: 'Rose',
      description: "château des bertrands / côtes de provence / consault & black grenache / '17",
      category: [4, 6],
      rank: 1,
    },
    {
      id: 24,
      price: 7,
      title: 'Chardonnay',
      description: "errazuriz / wild ferment / casablanca valley / chile / '15",
      category: [4, 7],
      rank: 2,
    },
    {
      id: 25,
      price: 7,
      title: 'Viognier',
      description: 'cline / sonoma valley, ca / organic / vegan / \'17',
      category: [4, 5],
      rank: 3,
    },
    {
      id: 2,
      price: 7,
      title: 'Red Blend',
      description: "l'oustalet / famille perrin / southern rhone / organic / '15",
      category: [4, 8],
      rank: 4,
    },
    {
      id: 2,
      price: 7,
      title: 'Rioja',
      description: "ontanon / ecologic red / spanish / organic / '15",
      category: [4, 7],
      rank: 5,
    },
    {
      id: 2,
      price: 9,
      title: 'Sherry',
      description: 'lustau / pedro ximenez / "san emilio"',
      category: [4, 8],
      rank: 6,
    },
    {
      id: 2,
      price: 9,
      title: 'Sherry',
      description: 'lustau / pedro ximenez / "san emilio"',
      category: [4, 8],
      rank: 6,
    },


    // BEER ******************************************************************
    {
      id: 2,
      price: 4,
      title: 'Naked Threesome IPA',
      description: 'raised grain / waukesha',
      category: [4, 9],
      rank: 28,
    },
    {
      id: 3,
      price: 4,
      title: 'Paradocs Red Imperial IPA',
      description: 'raised grain / waukesha',
      category: [4, 9],
      rank: 29,
    },
    {
      id: 3,
      price: 4,
      title: 'Blackwalnut Belgian Imperial Stout',
      description: 'raised grain / waukesha',
      category: [4, 9],
      rank: 30,
    },
    {
      id: 3,
      price: 4,
      title: 'Guitar City Gold',
      description: 'raised grain / waukesha',
      category: [4, 9],
      rank: 31,
    },

    // COCKTAILS *************************************************************
    {
      id: 3,
      price: 8,
      title: 'Mimosa',
      description: 'organic orange juice / prosecco',
      category: [4, 10],
      rank: 1,
    },
    {
      id: 3,
      price: 9,
      title: 'Bloody Mary',
      description: 'twisted path organic vodka / rohrganics tomato puree / worcestershire / tamari / horseradish',
      category: [4, 10],
      rank: 2,
    },
    {
      id: 3,
      price: 10,
      title: 'Ryte on Sherry',
      description: 'old overholt rye / lustau sherry / cocchi vermouth di torino / orange bitters',
      category: [4, 10],
      rank: 3,
    },
    {
      id: 3,
      price: 10,
      title: '¡Apple Olei',
      description: 'silver tequila / organic apple cider / lemon',
      category: [4, 10],
      rank: 4,
    },
    {
      id: 3,
      price: 10,
      title: 'Francophile',
      description: 'aeppeltreow apple brandy / red wine / cinnamon simple / lemon',
      category: [4, 10],
      rank: 5,
    },
    {
      id: 4,
      price: 8.5,
      title: 'The Scarlet Pear',
      description: 'scarlet ibis rum / mathilde poire / batavia arrack / lemon',
      category: [4, 10],
      rank: 6,
    },
    {
      id: 5,
      price: 10,
      title: 'Espresso Martini',
      description: 'twisted path organic vodka / espresso / irish cream / chocolate',
      category: [4, 10],
      rank: 7,
    },


    // CAFE DRINKS ***********************************************************
    {
      id: 3,
      title: 'Drip Coffee',
      price: [{id: 1, amount: '1.50'}, {id: 2, amount: '1.75'}, {id: 3, amount: '2'}],
      category: [11, 12, 13],
      rank: 37,
    },
    {
      id: 3,
      title: 'Macchiato',
      price: [{id: 1, amount: '3'}, {id: 2}, {id: 3}],
      category: [11, 12, 13],
      rank: 38,
    },
    {
      id: 4,
      title: 'Latte or Cappunccino',
      price: [{id: 1}, {id: 2, amount: '3.25'}, {id: 3, amount: '2.95'}],
      category: [11, 12, 13],
      rank: 39,
    },
    {
      id: 4,
      title: 'Americano',
      price: [{id: 1}, {id: 2, amount: '2.95'}, {id: 3}],
      category: [11, 12, 13],
      rank: 40,
    },
    {
      id: 4,
      title: 'Overdrive',
      price: [{id: 1, amount: '3'}, {id: 2, amount: '3.15'}, {id: 3, amount: '3.25'}],
      category: [11, 12, 13],
      rank: 41,
    },
    {
      id: 4,
      title: 'Cafe au lait',
      price: [{id: 1}, {id: 2, amount: '2.75'}, {id: 3, amount: '3.25 '}],
      category: [11, 12, 13],
      rank: 42,
    },

    { id: 4, title: 'Fruit Smoothies', price: [{id: 1}, {id: 2, amount: '5'}, {id: 3}], category: [11, 12, 14], rank: 43, },
    { id: 4, title: 'Chai Tea', price: [{id: 1, amount: '2.75'}, {id: 2, amount: '3.25'}, {id: 3, amount: '3.75'}], category: [11, 12, 14], rank: 44, },
    { id: 4, title: 'Hot Chocolate', price: [{id: 1, amount: '2.50'}, {id: 2, amount: '3'}, {id: 3, amount: '3.50'}], category: [11, 12, 14], rank: 45, },
    { id: 4, title: 'Steamer', price: [{id: 1, amount: '2.25'}, {id: 2, amount: '2.75'}, {id: 3, amount: '3.25'}], category: [11, 12, 14], rank: 46, },
    { id: 4, title: 'Loose leaf / Bagged tea', price: [{id: 1}, {id: 2, amount: '2'}, {id: 3}], category: [11, 12, 14], rank: 47, },
    { id: 4, title: 'Iced Tea', price: [{id: 1}, {id: 2, amount: '2.25'}, {id: 3, amount: '2.75'}], category: [11, 12, 14], rank: 48, },

    // EXTRAS ****************************************************************
    { id: 5, title: 'Flavor shot', price: [{ id: 1, amount: '.50'}, {id: 2}, {id: 3}], category: [11, 12, 15], rank: 49, },
    { id: 5, title: 'Whipped Cream', price: [{ id: 1, amount: '.55'}, {id: 2}, {id: 3}], category: [11, 12, 15], rank: 50, },
    { id: 5, title: 'Almond Milk', price: [{ id: 1, amount: '1'}, {id: 2}, {id: 3}], category: [11, 12, 15], rank: 51, },
    { id: 5, title: 'Breve', price: [{ id: 1, amount: '1'}, {id: 2}, {id: 3}], category: [11, 12, 15], rank: 52, },
    { id: 5, title: 'Espresso Shot', price: [{ id: 1, amount: '3'}, {id: 2}, {id: 3}], category: [11, 12, 15], rank: 53, }

  ]
};
