import React from 'react'
import { withRouter } from 'react-router-dom'

const Error404 = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
)

export default withRouter(Error404)
