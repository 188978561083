import React from 'react'

import {
  ImageSlider,
  ContactCard,
  MenuCafe,
  MenuKitchen,
  CustomerSuggestions,
  MailingListSignup
} from '../blocks'

export default (props) => (
  <React.Fragment>

    <ImageSlider />

    <div style={{ marginTop: 20 }}>
      <ContactCard {...props} />
    </div>

    <MenuCafe {...props} />

    <MenuKitchen {...props} />

    <CustomerSuggestions {...props} />

    <MailingListSignup {...props} />

  </React.Fragment>
)
